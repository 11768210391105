<template>
    <v-card class="card-shadow border" color="white">
        <v-responsive class="mx-auto pa-8" max-width="650px">
            <v-card-title class="headline center" >Create Role</v-card-title>
            <v-form @submit.prevent="submitForm">
            <v-text-field
                v-model="roleName"
                :rules="nameRules"
                label="Role Name"
                required >
            </v-text-field>
            <v-card-title v-if="successMessage" class="success-sec-btn mb-2">{{ successMessage }}</v-card-title>
            <v-card-title v-if="errorMessage" class="error-sec-btn mb-2">{{ errorMessage }}</v-card-title>
            <v-btn color="primary" type="submit">Create</v-btn>
            </v-form>
        </v-responsive>
    </v-card>
</template>
<script>

export default {


  data: () => ({
    data: '',
    roleName: '',
    successMessage: '',
    errorMessage: '',
    nameRules: [
      v => !!v || 'Role name is required'
    ]
  }),

  props: {
    currentServer: Object,
  },

  methods: {
    async submitForm() {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/roles`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            'name': this.roleName,
          }),
        })
        const data = await response.json()
        this.data = JSON.stringify(response)
        if(response.status === 201){
          this.successMessage = 'Role created successfully'
          setTimeout(() => {
            this.successMessage = '';
          }, 8000);

        }
        else {
          this.errorMessage = data.error;
          setTimeout(() => {
            this.errorMessage = '';
          }, 8000);
        }
      } catch (error) {
        this.errorMessage = 'Unauthenticated';
        setTimeout(() => {
          this.errorMessage = ''
        }, 8000);
        this.$router.push('/admin-login');
      }
    }
  },
};
</script>
